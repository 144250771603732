<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "AppPubEvent",
		extends: Base,
		data() {
			return {
				Name: "AppPubEvent",
				mrLevel: {},
				mrCat: [],
				filter: {
					status: ''
				}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'(v) {
				if (!v) return
				this.search()
			},
		},

		methods: {}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List </h5>
								</div>
								<div class="col-sm-3">
									<select v-model="filter.status" @change="search()" class="form-control"
										placeholder='Status'>
										<option value=""> -- Filter by Status --</option>
										<option value="D">Draft</option>
										<option value="P">Publish</option>
									</select>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="apb_title_id"></SortField>
									</th>
									<th>Category</th>
									<th>Author</th>
									<th>Start Date</th>
									<th>End Date</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.apb_title_id}}</td>
									<td>{{v.abc_name_id}}</td>
									<td>{{v.apb_author}}</td>
									<td>{{(v.apb_publish_date||"").dates("format")}}</td>
									<td>{{(v.apb_end_date||"").dates("format")}}</td>
									<td>
										<StatusLabel :type="'blog'" :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'">
											<i class="ti-marker-alt"></i>
										</router-link>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
											v-tooltip="'Remove'">
											<i class="ti-trash"></i>
										</a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-8">
							<BoField name="apb_title_en" v-model="row.apb_title_en"></BoField>
							<BoField name="apb_title_id" v-model="row.apb_title_id"></BoField>
							<BoField name="apb_excerpt_en">
								<textarea class="form-control" v-model="row.apb_excerpt_en"></textarea>
							</BoField>
							<BoField name="apb_excerpt_id">
								<textarea class="form-control" v-model="row.apb_excerpt_id"></textarea>
							</BoField>

							<BoField name="apb_desc_en">
								<CKEditor name="content" class="form-control" v-model="row.apb_desc_en"></CKEditor>
							</BoField>
							<BoField name="apb_desc_id">
								<CKEditor name="pn_content" class="form-control" v-model="row.apb_desc_id"></CKEditor>
							</BoField>
						</div>
						<div class="col-4">
							<BoField name="apb_image">
								<Uploader name="apb_image" :param="{thumbnail:true}" type="blog" uploadType="cropping"
									v-model="row.apb_image"></Uploader>
							</BoField>
							<BoField name="apb_image_view">
								<Uploader name="apb_image_view" :param="{thumbnail:true}" type="blog_view"
									uploadType="cropping" v-model="row.apb_image_view"></Uploader>
							</BoField>
							<BoField name="apb_author" v-model="row.apb_author"></BoField>
							<BoField name="apb_publish_date" :label="'Start Date'">
								<DatePicker v-model="row.apb_publish_date" placeholder="Start Date"></DatePicker>
							</BoField>
							<BoField name="apb_end_date">
								<DatePicker v-model="row.apb_end_date" placeholder="End Date"></DatePicker>
							</BoField>
							<BoField name="apb_status">
								<div class="row">
									<div class="col-3">
										<radio name="apb_status" v-model="row.apb_status" option="P"
											:attr="validation('apb_status')">Publish</radio>
									</div>
									<div class="col-3">
										<radio name="apb_status" v-model="row.apb_status" option="D">Draft</radio>
									</div>
								</div>
							</BoField>
						</div>

						<div class="col-6">
							<BoField name="apb_tags_en">
								<TagsInput name="apb_tags_en" style="display:block" v-model="row.apb_tags_en"
									:attr="validation('apb_tags_en')"></TagsInput>
							</BoField>
							<BoField name="apb_meta_keyword_en">
								<TagsInput name="apb_meta_keyword_en" style="display:block"
									v-model="row.apb_meta_keyword_en" :attr="validation('apb_meta_keyword_en')">
								</TagsInput>
							</BoField>
							<BoField name="apb_meta_desc_en">
								<textarea class="form-control" rows="6" v-model="row.apb_meta_desc_en"></textarea>
							</BoField>
						</div>
						<div class="col-6">
							<BoField name="apb_tags_id">
								<TagsInput name="apb_tags_id" style="display:block" v-model="row.apb_tags_id"
									:attr="validation('apb_tags_id')"></TagsInput>
							</BoField>
							<BoField name="apb_meta_keyword_id">
								<TagsInput name="apb_meta_keyword_id" style="display:block"
									v-model="row.apb_meta_keyword_id" :attr="validation('apb_meta_keyword_id')">
								</TagsInput>
							</BoField>
							<BoField name="apb_meta_desc_id">
								<textarea class="form-control" rows="6" v-model="row.apb_meta_desc_id"></textarea>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="btn btn-info btn-outline btn-rounded btn-loading">Save
									Event</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>